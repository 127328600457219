import DashboardLayout from "@/pages/layouts/DashboardLayout";

let toppingRoutes = {
  path: '/toppings',
  component: DashboardLayout,
  redirect: '/toppings/list',
  children: [
    {
      path: 'list',
      name: 'Topping List',
      component: () => import(/* webpackChunkName: "toppings" */  '@/pages/meals/toppings/ToppingList'),
      meta: {
        permissionsCodes: ['toppings/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Topping',
      component: () => import(/* webpackChunkName: "toppings" */  '@/pages/meals/toppings/ToppingForm'),
      meta: {
        permissionsCodes: ['toppings/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Topping',
      component: () => import(/* webpackChunkName: "toppings" */  '@/pages/meals/toppings/ToppingForm'),
      meta: {
        permissionsCodes: ['toppings/update'],
      }
    }
  ]
}

export default toppingRoutes;
