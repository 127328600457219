import DashboardLayout from "@/pages/layouts/DashboardLayout";

let mealRoutes = {
  path: '/meals',
  component: DashboardLayout,
  redirect: '/meals/list',
  children: [
    {
      path: 'list',
      name: 'Meal List',
      component: () => import(/* webpackChunkName: "meals" */  '@/pages/meals/meals/MealList'),
      meta: {
        permissionsCodes: ['meals/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Meal',
      component: () => import(/* webpackChunkName: "meals" */  '@/pages/meals/meals/MealForm'),
      meta: {
        permissionsCodes: ['meals/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Meal',
      component: () => import(/* webpackChunkName: "meals" */  '@/pages/meals/meals/MealForm'),
      meta: {
        permissionsCodes: ['meals/update'],
      }
    }
  ]
}

export default mealRoutes;
