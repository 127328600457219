import DashboardLayout from "@/pages/layouts/DashboardLayout";

let subscriptionPlanRoutes = {
  path: '/subscription-plans',
  component: DashboardLayout,
  redirect: '/subscription-plans/list',
  children: [
    {
      path: 'list',
      name: 'Subscription Plan List',
      component: () => import(/* webpackChunkName: "subscription-plans" */  '@/pages/subscriptions/subscriptionPlans/SubscriptionPlanList'),
      meta: {
        permissionsCodes: ['subscription-plans/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Subscription Plan',
      component: () => import(/* webpackChunkName: "subscription-plans" */  '@/pages/subscriptions/subscriptionPlans/SubscriptionPlanForm'),
      meta: {
        permissionsCodes: ['subscription-plans/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Subscription Plan',
      component: () => import(/* webpackChunkName: "subscription-plans" */  '@/pages/subscriptions/subscriptionPlans/SubscriptionPlanForm'),
      meta: {
        permissionsCodes: ['subscription-plans/update'],
      }
    },
  ]
}

export default subscriptionPlanRoutes;
