import DashboardLayout from "@/pages/layouts/DashboardLayout";

let menuRoutes = {
  path: '/menus',
  component: DashboardLayout,
  redirect: '/menus/list',
  children: [
    {
      path: 'list',
      name: 'Menu List',
      component: () => import(/* webpackChunkName: "menus" */  '@/pages/menus/MenuList'),
      meta: {
        permissionsCodes: ['menus/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Menu',
      component: () => import(/* webpackChunkName: "menus" */  '@/pages/menus/MenuForm'),
      meta: {
        permissionsCodes: ['menus/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Menu',
      component: () => import(/* webpackChunkName: "menus" */  '@/pages/menus/EditMenuForm'),
      meta: {
        permissionsCodes: ['menus/update'],
      }
    }
  ]
}

export default menuRoutes;
