import DashboardLayout from "@/pages/layouts/DashboardLayout";

let parentRoutes = {
  path: '/parents',
  component: DashboardLayout,
  redirect: '/parents/list',
  children: [
    {
      path: 'list',
      name: 'Parent List',
      component: () => import(/* webpackChunkName: "parents" */  '@/pages/parents/ParentList'),
      meta: {
        permissionsCodes: ['parents/index'],
      }
    },
    {
      path: 'view-children/:id',
      name: 'View Parent Children',
      component: () => import(/* webpackChunkName: "parents" */  '@/pages/children/ChildrenList'),
      meta: {
        permissionsCodes: ['parents/view-children'],
      }
    },
    {
      path: 'deleted-parents',
      name: 'Deleted Parents List',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/parents/DeletedParentList'),
      meta: {
        permissionsCodes: ['parents/deleted-parents'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Parents',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/parents/ParentForm'),
      meta: {
        permissionsCodes: ['parents/edit-parents'],
      }
    },
    {
      path: 'changePassword/:id',
      name: 'Change Parents Password',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/parents/ChangeParentPassword'),
      meta: {
        permissionsCodes: ['parents/edit-parents'],
      }
    }
  ]
}

export default parentRoutes;
