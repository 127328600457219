import DashboardLayout from "@/pages/layouts/DashboardLayout";

let schoolRoutes = {
  path: '/schools',
  component: DashboardLayout,
  redirect: '/schools/list',
  children: [
    {
      path: 'list',
      name: 'Schools List',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/schools/SchoolList'),
      meta: {
        permissionsCodes: ['schools/index'],
      }
    },
    {
      path: 'create',
      name: 'Add School',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/schools/SchoolForm'),
      meta: {
        permissionsCodes: ['schools/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit School',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/schools/SchoolForm'),
      meta: {
        permissionsCodes: ['schools/update'],
      }
    },
    {
      path: 'view-students/:id',
      name: 'View School Students',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/children/ChildrenList'),
      meta: {
        permissionsCodes: ['schools/view-students'],
      }
    },
    {
      path: 'calendar/:id',
      name: 'View Calendar',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/calendars/CalendarForm'),
      meta: {
        permissionsCodes: ['schools/calendar'],
      }
    },
    {
      path: 'deleted-schools',
      name: 'Deleted Schools List',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/schools/DeletedSchoolList'),
      meta: {
        permissionsCodes: ['schools/deleted-schools'],
      }
    },
    {
      path: 'changePassword/:id',
      name: 'Change Schools Password',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/schools/ChangeParentPassword'),
      meta: {
        permissionsCodes: ['schools/edit-schools'],
      }
    }
    // {
    //   path: 'resend-welcome-email/:id',
    //   name: 'Resend welcome email',
    //   component: () => import(/* webpackChunkName: "schools" */  '@/pages/schools/SchoolList'),
    //   meta: {
    //     permissionsCodes: ['schools/resend-welcome-email'],
    //   }
    // },
  ]
}

export default schoolRoutes;
