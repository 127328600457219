import DashboardLayout from "@/pages/layouts/DashboardLayout";

let packageRoutes = {
  path: '/packages',
  component: DashboardLayout,
  redirect: '/packages/list',
  children: [
    {
      path: 'list',
      name: 'Packaging List',
      component: () => import(/* webpackChunkName: "packages" */  '@/pages/packages/PackageList'),
      meta: {
        permissionsCodes: ['packages/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Package',
      component: () => import(/* webpackChunkName: "packages" */  '@/pages/packages/PackageForm'),
      meta: {
        permissionsCodes: ['packages/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Package',
      component: () => import(/* webpackChunkName: "packages" */  '@/pages/packages/PackageForm'),
      meta: {
        permissionsCodes: ['packages/update'],
      }
    }
  ]
}

export default packageRoutes;
