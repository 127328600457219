import DashboardLayout from "@/pages/layouts/DashboardLayout";

let calendarRoutes = {
  path: '/calendar',
  component: DashboardLayout,
  redirect: '/calendar/list',
  children: [
    {
      path: 'create/:id',
      name: 'Add Calendar',
      component: () => import(/* webpackChunkName: "calendar" */  '@/pages/calendars/CalendarForm'),
      meta: {
        permissionsCodes: ['calendar/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Calendar',
      component: () => import(/* webpackChunkName: "calendar" */  '@/pages/calendars/CalendarForm'),
      meta: {
        permissionsCodes: ['calendar/update'],
      }
    }
  ]
}

export default calendarRoutes;
