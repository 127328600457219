import DashboardLayout from "@/pages/layouts/DashboardLayout";

let subscriptionRoutes = {
  path: '/subscriptions',
  component: DashboardLayout,
  redirect: '/subscriptions/list',
  children: [
    {
      path: 'list',
      name: 'Subscription List',
      component: () => import(/* webpackChunkName: "subscription-plans" */  '@/pages/subscriptions/SubscriptionList'),
      meta: {
        permissionsCodes: ['subscriptions/index'],
      }
    },
    {
      path: 'view/:id',
      name: 'View Subscription',
      component: () => import(/* webpackChunkName: "subscription-plans" */  '@/pages/subscriptions/SubscriptionView'),
      meta: {
        permissionsCodes: ['subscriptions/view'],
      }
    },
  ]
}

export default subscriptionRoutes;
