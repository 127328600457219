var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{'nav-open': _vm.$sidebar.showSidebar}},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}}}),_c('sidebar-item',{attrs:{"link":{name: 'Subscriptions', icon: 'fas fa-shipping-fast', path: '/subscriptions/list'}}}),(_vm.$store.getters['auth/haveOneOfPermissions'](['schools/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Schools', icon: 'fa fa-school', path: '/schools/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['staff/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Staff', icon: 'fas fa-user-tie', path: '/staff/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['parents/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Parents', icon: 'nc-icon nc-single-02', path: '/parents/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['menus/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Menus', icon: 'nc-icon nc-bullet-list-67', path: '/menus/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['subscription-plans/index']))?_c('sidebar-item',{attrs:{"link":{name: 'subscription Plans', icon: 'nc-icon nc-ruler-pencil', path: '/subscription-plans/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['meals/index',
                                          'toppings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Meals', icon: 'fas fa-hamburger'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['meals/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Meals', path: '/meals/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['toppings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Toppings', path: '/toppings/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['addOns/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Sides', icon: 'fa fa-cookie-bite', path: '/addOns'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['beverages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Beverages',  icon: 'fa fa-mug-hot', path: '/beverages'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['packages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Food Packaging', icon: 'fas fa-box', path: '/packages'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['contact-us/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['newsletter/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                                          (['pages/index',
                                           'content-pages/create',
                                           'redirection-pages/create',
                                           'branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['pages/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Existing Pages', path: '/pages/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['content-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Content Page', path: '/content-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create']))?_c('sidebar-item',{attrs:{"link":{name: 'Add Redirection Page', path: '/redirection-pages/create'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['branches/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Branches', path: '/branches/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions']
                     (['panel-groups/index','panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}}},[(_vm.$store.getters['auth/haveOneOfPermissions'](['panels/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Panels', path: '/panels/list'}}}):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['panel-groups/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Groups', path: '/panel-groups/list'}}}):_vm._e()],1):_vm._e(),(_vm.$store.getters['auth/haveOneOfPermissions'](['settings/index']))?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('roles/index')
                          // || $store.getters['auth/checkAccess']('permissions/index')
                          || _vm.$store.getters['auth/checkAccess']('users/index')
                          || _vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Access Management', icon: 'nc-icon nc-circle-09'}}},[(_vm.$store.getters['auth/checkAccess']('roles/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Roles', path: '/roles/list'}}}):_vm._e(),(false)?_c('sidebar-item',{attrs:{"link":{name: 'Permissions', path: '/permissions/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/index'))?_c('sidebar-item',{attrs:{"link":{name: 'Users', path: '/users/list'}}}):_vm._e(),(_vm.$store.getters['auth/checkAccess']('users/deleted-users'))?_c('sidebar-item',{attrs:{"link":{name: 'Deleted Users', path: '/users/DeletedUsersList'}}}):_vm._e()],1):_vm._e()]}}])},[_c('user-menu'),_c('mobile-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }