import DashboardLayout from "@/pages/layouts/DashboardLayout";

let childRoutes = {
  path: '/children',
  component: DashboardLayout,
  redirect: '/children/view/:id',
  children: [
    {
      path: 'view/:id',
      name: 'Child information',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/children/ChildView'),
      meta: {
        permissionsCodes: ['children/get'],
      }
    },
  ]
}

export default childRoutes;
