import DashboardLayout from "@/pages/layouts/DashboardLayout";

let beverageRoutes = {
  path: '/beverages',
  component: DashboardLayout,
  redirect: '/beverages/list',
  children: [
    {
      path: 'list',
      name: 'Beverage List',
      component: () => import(/* webpackChunkName: "beverages" */  '@/pages/beverages/BeverageList'),
      meta: {
        permissionsCodes: ['beverages/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Beverage',
      component: () => import(/* webpackChunkName: "beverages" */  '@/pages/beverages/BeverageForm'),
      meta: {
        permissionsCodes: ['beverages/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Beverage',
      component: () => import(/* webpackChunkName: "beverages" */  '@/pages/beverages/BeverageForm'),
      meta: {
        permissionsCodes: ['beverages/update'],
      }
    }
  ]
}

export default beverageRoutes;
