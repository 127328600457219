import DashboardLayout from "@/pages/layouts/DashboardLayout";

let staffRoutes = {
  path: '/staff',
  component: DashboardLayout,
  redirect: '/staff/list',
  children: [
    {
      path: 'list',
      name: 'Staff List',
      component: () => import(/* webpackChunkName: "staff" */  '@/pages/staff/StaffList'),
      meta: {
        permissionsCodes: ['staff/index'],
      }
    },
    {
      path: 'view-subscriptions/:id',
      name: 'View Subscriptions',
      component: () => import(/* webpackChunkName: "staff" */  '@/pages/staff/StaffSubscriptionsView'),
      meta: {
        permissionsCodes: ['staff/view-subscriptions'],
      }
    },
    {
      path: 'changePassword/:id',
      name: 'Change Staff Password',
      component: () => import(/* webpackChunkName: "schools" */  '@/pages/staff/ChangeParentPassword'),
      meta: {
        permissionsCodes: ['Staff/edit-Staff'],
      }
    }
    // {
    //   path: 'deleted-staff',
    //   name: 'Deleted Staff List',
    //   component: () => import(/* webpackChunkName: "schools" */  '@/pages/staff/DeletedStaffList'),
    //   meta: {
    //     permissionsCodes: ['staff/deleted-staff'],
    //   }
    // },
  ]
}

export default staffRoutes;
