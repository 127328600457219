<template>

  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>

    <side-bar>

      <user-menu></user-menu>

      <mobile-menu></mobile-menu>

      <template slot-scope="props" slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/overview'}">
        </sidebar-item>

        <sidebar-item :link="{name: 'Subscriptions', icon: 'fas fa-shipping-fast', path: '/subscriptions/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['schools/index'])"
                      :link="{name: 'Schools', icon: 'fa fa-school', path: '/schools/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['staff/index'])"
                      :link="{name: 'Staff', icon: 'fas fa-user-tie', path: '/staff/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['parents/index'])"
                      :link="{name: 'Parents', icon: 'nc-icon nc-single-02', path: '/parents/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['menus/index'])"
                      :link="{name: 'Menus', icon: 'nc-icon nc-bullet-list-67', path: '/menus/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['subscription-plans/index'])"
                      :link="{name: 'subscription Plans', icon: 'nc-icon nc-ruler-pencil', path: '/subscription-plans/list'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['meals/index',
                                            'toppings/index'])"
                      :link="{name: 'Meals', icon: 'fas fa-hamburger'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['meals/index'])"
            :link="{name: 'Meals', path: '/meals/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['toppings/index'])"
            :link="{name: 'Toppings', path: '/toppings/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['addOns/index'])"
                      :link="{name: 'Sides', icon: 'fa fa-cookie-bite', path: '/addOns'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['beverages/index'])"
                      :link="{name: 'Beverages',  icon: 'fa fa-mug-hot', path: '/beverages'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['packages/index'])"
                      :link="{name: 'Food Packaging', icon: 'fas fa-box', path: '/packages'}">
        </sidebar-item>


        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['contact-us/index'])"
                      :link="{name: 'Contact Messages', icon: 'nc-icon nc-support-17', path: '/contact-us'}">
        </sidebar-item>
        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['newsletter/index'])"
                                :link="{name: 'Newsletter Subscribers', icon: 'nc-icon nc-email-83', path: '/newsletter'}">
        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                                            (['pages/index',
                                             'content-pages/create',
                                             'redirection-pages/create',
                                             'branches/index'])"
                      :link="{name: 'Pages', icon: 'nc-icon nc-single-copy-04'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['pages/index'])"
            :link="{name: 'Existing Pages', path: '/pages/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['content-pages/create'])"
                        :link="{name: 'Add Content Page', path: '/content-pages/create'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['redirection-pages/create'])"
                        :link="{name: 'Add Redirection Page', path: '/redirection-pages/create'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['branches/index'])"
            :link="{name: 'Branches', path: '/branches/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions']
                       (['panel-groups/index','panels/index'])"
                      :link="{name: 'Panels And Banners', icon: 'nc-icon nc-album-2'}">

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panels/index'])"
            :link="{name: 'Panels', path: '/panels/list'}"></sidebar-item>

          <sidebar-item
            v-if="$store.getters['auth/haveOneOfPermissions'](['panel-groups/index'])"
            :link="{name: 'Groups', path: '/panel-groups/list'}"></sidebar-item>

        </sidebar-item>

        <sidebar-item v-if="$store.getters['auth/haveOneOfPermissions'](['settings/index'])"
                      :link="{name: 'Settings', icon: 'nc-icon nc-settings-gear-64', path: '/settings/list'}">
        </sidebar-item>


        <!--</sidebar-item>-->

        <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')
                            // || $store.getters['auth/checkAccess']('permissions/index')
                            || $store.getters['auth/checkAccess']('users/index')
                            || $store.getters['auth/checkAccess']('users/deleted-users')"
                      :link="{name: 'Access Management', icon: 'nc-icon nc-circle-09'}">

          <sidebar-item v-if="$store.getters['auth/checkAccess']('roles/index')"
                        :link="{name: 'Roles', path: '/roles/list'}"></sidebar-item>
          <sidebar-item v-if="false"
                        :link="{name: 'Permissions', path: '/permissions/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/index')"
                        :link="{name: 'Users', path: '/users/list'}"></sidebar-item>

          <sidebar-item v-if="$store.getters['auth/checkAccess']('users/deleted-users')"
                        :link="{name: 'Deleted Users', path: '/users/DeletedUsersList'}"></sidebar-item>

        </sidebar-item>


      </template>

    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>

  </div>
</template>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './Extra/MobileMenu.vue'
import UserMenu from './Extra/UserMenu.vue'


export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    }
  }
}

</script>
